import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

import { Toastr } from "../common/toastr";
import { restPost, restPut } from "../../store/api";
import { restGet } from "../../store/api";
import { getUserDetails } from "../../store/actions";
import { updateCurrentUser } from "../../store/actions";
import { products } from "./products";
import { ReactComponent as VisaIcon } from "../../assets/svg/visa.svg";
import { ReactComponent as MasterCardIcon } from "../../assets/svg/mastercard.svg";
import { ReactComponent as AmexIcon } from "../../assets/svg/amex.svg";
import BlackBtn from "../buttons/blackBtn";
import CheckoutToDashboard from "../../pages/dashboard/checkout-to-dashboard";

const stripePromise = loadStripe("pk_live_51KwXFSEIVqRzBbsHpltTw6nujzP03gunYxpvucEToZXmUwOaDZaGcGL4BOfwp9f88aYsg42mun9ClqI6vL0NI2aY00JbPAIYcZ");

const cardElementOptions = {
  style: {
    base: {
      fontSize: "16px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
  hidePostalCode: true,
};

export const PaymentWrapper = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <Payment {...props} />
    </Elements>
  );
};

function Payment(props) {
  const dispatch = useDispatch();

  const key = props.value[0];
  const value = props.value[1];
  const total = parseInt(value);
  const stripe = useStripe();
  const elements = useElements();

  const user = useSelector((state) => state.auth.currentUser);
  const user1 = useSelector((state) => state.auth.user);

  const [totalPrice, setTotalPrice] = useState(total);
  const [valuePercentOff, setValuePercentOff] = useState(null);
  const [couponId, setCouponId] = useState(null);
  const [promotionCodeId, setPromotionCodeId] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [promotionCode, setPromotionCode] = useState("");
  const [showCheckoutToDashboard, setShowCheckoutToDashboard] = useState(false);

  let product = [];
  if (coupon === "one") {
    product = products.find((p) => p.id === parseInt(15));
  } else if (coupon === "five") {
    product = products.find((p) => p.id === parseInt(5));
  } else if (coupon === "three") {
    product = products.find((p) => p.id === parseInt(22));
  } else if (coupon === "lifetime") {
    product = products.find((p) => p.id === parseInt(6));
  } else if (coupon === "delete") {
    product = products.find((p) => p.id === parseInt(key));
  } else {
    product = products.find((p) => p.id === parseInt(key));
  }

  const name = user?.attributes.name;
  const email = user?.attributes.email;

  const successCallBack = async (data) => {
    await restGet(
      "/users/show",
      (data) => {
        dispatch(getUserDetails(data));
        user["expiredSubscriptionDate"] = data.expiredSubscriptionDate;
        dispatch(updateCurrentUser(user));
      },
      (err) => console.error(err)
    );

    const reqBody = {
      name: name,
      email: email,
      uid: user1?.uid,
    };

    restPost(
      "/email/subscription",
      reqBody,
      () => {
        console.info("Email sent successfully.");
      },
      (err) => console.error(err)
    );

    setShowCheckoutToDashboard(true);
    Toastr({ type: "success", message: "Payment succeeded." });
  };

  const failCallback = (err) => {
    Toastr({ type: "error", message: `Error: ${err}` });
  };

  const successUpdateCallBack = async (data) => {
    console.info("success");
  };

  const failUpdateCallback = (err) => {
    console.error(`error: ${err}`);
  };

  const couponCallBack = async (data) => {
    const body = { active: false };

    restPut(`/coupons/${promotionCodeId}`, body, successUpdateCallBack, failUpdateCallback);
  };

  const couponFailCallback = (err) => {
    console.error(`error: ${err}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { email, address, city, region, postalCode, promotionCode } = event.target;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        address: {
          line1: address.value,
          city: city.value,
          state: region.value,
          postal_code: postalCode.value,
        },
        email: email.value,
      },
    });

    if (error) {
      Toastr({ type: "error", message: `Error: ${error.message}` });
    } else {
      const expirationDate = new Date();
      const reqBody = {
        paymentMethod: paymentMethod,
        paymentMethodId: paymentMethod.id,
        email: email.value,
        address: {
          line1: address.value,
          city: city.value,
          state: region.value,
          postal_code: postalCode.value,
        },
        amount: totalPrice,
        description: "Payment for registration manually",
        expiredTime: expirationDate.toISOString(),
        days: 365,
        planName: product.product,
        priceId: product.priceId,
        productId: product.id,
        products: products,
      };

      if (promotionCode.value) {
        const typePromotionCode = product.type;
        reqBody["typePromotionCode"] = typePromotionCode;
      }

      if (totalPrice === 0 && promotionCode !== "" && couponId !== "") {
        await restPost("/payments/new-with-coupon", reqBody, successCallBack, failCallback)
      } else {
        await restPost("/payments/new", reqBody, successCallBack, failCallback);
      }

      if (promotionCode.value) {
        const body = {
          id: couponId,
          toEmail: user.attributes.email,
          percentOff: valuePercentOff,
          code: promotionCode.value,
          uid: user?.username,
        };

        await restPost("/coupons", body, couponCallBack, couponFailCallback);
      }
    }
  };

  const handlePromotionCodeSubmit = async (event) => {
    restGet(
      `/coupons?promotion=${promotionCode}&type=launching`,
      (indexData) => {
        if (indexData.length > 0) {
          Toastr({
            type: "error",
            message: "This coupon has already been redeemed",
          });
        } else {
          restGet(
            `/stripe/coupons?promotion=${promotionCode}`,
            (data) => {
              const percentOff = data.coupon.percent_off;

              setTotalPrice(totalPrice * (1 - percentOff / 100));
              setValuePercentOff(data.coupon.percent_off);
              setCouponId(data.coupon.id);
              setPromotionCodeId(data.id);

              if (!data.length) {
                restGet(
                  `/coupons?promotion=${promotionCode}&status=false`,
                  (indexData) => {
                    if (indexData.length === 0 && promotionCode.length === 0) {
                      setTotalPrice(total);
                    } else {
                      const indexPercentOff = indexData[0]?.percentOff || 100;

                      setTotalPrice(totalPrice * (1 - indexPercentOff / 100));

                      Toastr({
                        type: "success",
                        message: "Applied promotion code",
                      });
                    }
                  },
                  (error) => {
                    console.error(error);
                  }
                );
              }

              if (promotionCode.toLowerCase().includes("one")) {
                setCoupon("one");
              } else if (promotionCode.toLowerCase().includes("five")) {
                setCoupon("five");
              } else if (promotionCode.toLowerCase().includes("lifetime")) {
                setCoupon("lifetime");
              } else if (promotionCode.toLowerCase().includes("3year")) {
                setCoupon("three");
              } else if (promotionCode.toLowerCase().includes("5yr")) {
                setCoupon("five");
              }
            },
            (error) => {
              if (error.response?.data?.error === "Promotion code not found") {
                Toastr({
                  type: "error",
                  message: "Promotion code not found",
                });
              }
            }
          );
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    setTotalPrice(product.rate);
  }, [product]);

  return (
    <>
      {showCheckoutToDashboard && <CheckoutToDashboard />}

      <div className="mt-12 flex flex-col-reverse lg:flex-row border-2">
        <div className="flex-1 bg-white flex items-start justify-center border-r-black border">
          <section className="py-6 text-black md:px-10 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg px-4 lg:px-5 md:py-10 lg:py-12 flex flex-col items-start">
            <div className="mx-auto max-w-2xl lg:max-w-none">
              <dl>
                <dt className="font-medium">Amount due</dt>

                <dd className="mt-1 text-4xl font-semibold">${totalPrice}</dd>
              </dl>

              <ul className="divide-y divide-black divide-opacity-10 md:text-lg font-medium">
                <Fragment key={product?.id}>
                  {
                    <li className="flex items-start space-x-4 py-6">
                      <div className="flex-auto space-y-1 font-light">
                        <p>
                          {product?.product}: <span className="font-medium">${totalPrice}</span>
                        </p>
                        {product?.subTitle && <p className="md:w-5/6 text-xs md:text-sm">{product?.subTitle}</p>}
                      </div>
                    </li>
                  }
                </Fragment>
              </ul>

              <dl className="space-y-6 border-t border-black border-opacity-10 pt-6 md:text-lg font-medium">
                <div className="flex items-center justify-between">
                  <dt>Subtotal</dt>
                  <dd>${totalPrice}</dd>
                </div>

                <div className="flex items-center justify-between border-t border-black border-opacity-10 pt-6 ">
                  <dt>Total due</dt>
                  <dd>${totalPrice}</dd>
                </div>
              </dl>
            </div>

            <div className="mt-6 flex items-center justify-between">
              <div className="relative pt-20 mx-auto max-w-sm space-y-4">
                <p className="text-primary-green font-bold uppercase">IF YOU HAVE A SPECIAL LICENSE CODE</p>
                <ol className="ml-5 list-[lower-number] space-y-2">
                  <li>Enter your license code in the “Promotional code" field to the right.</li>
                  <li>Enter your credit card details. YOU WILL NOT BE CHARGED. This information is required for you to register with My Banyan.</li>
                  <li>Click the "APPLY" button.</li>
                  <li>Your "Amount Due" will be zero.</li>
                </ol>

                <div className="hidden absolute right-[-10px] top-60 pl-8 lg:flex items-center">
                  <FontAwesomeIcon className="text-primary-green text-3xl" icon={faCaretRight} />
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="flex-1 bg-white">
          <section className="py-6 md:py-10 lg:py-12 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:px-5">
            <form onSubmit={handleSubmit}>
              <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                <div>
                  <div className="mt-6">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        name="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-green focus:ring-primary-green sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-10">
                  <div className="mt-6">
                    <label className="mb-3" htmlFor="card">
                      Card information <span className="text-red-500">*</span>
                    </label>
                    <CardElement options={cardElementOptions} />
                  </div>
                </div>

                <div className="flex items-left">
                  <div className="mr-4">
                    <VisaIcon className="w-8 h-8" alt="VISA" />
                  </div>

                  <div className="mr-4">
                    <MasterCardIcon className="w-8 h-8" alt="MasterCard" />
                  </div>

                  <div>
                    <AmexIcon className="w-8 h-8" alt="American Express" />
                  </div>
                </div>

                <div>
                  <div className="mt-6">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Name on card <span className="text-red-500">*</span>
                    </label>

                    <div className="mt-1">
                      <input
                        type="input"
                        name="name"
                        autoComplete="name"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-green focus:ring-primary-green sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-10">
                  <h3 id="shipping-heading" className="text-lg font-medium text-gray-900">
                    Billing address <span className="text-red-500">*</span>
                  </h3>

                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
                    <div className="sm:col-span-3">
                      <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                        Address
                      </label>

                      <div className="mt-1">
                        <input
                          type="text"
                          name="address"
                          autoComplete="street-address"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-green focus:ring-primary-green sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                        City
                      </label>

                      <div className="mt-1">
                        <input
                          type="text"
                          name="city"
                          autoComplete="address-level2"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-green focus:ring-primary-green sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                        State/Province
                      </label>

                      <div className="mt-1">
                        <input
                          type="text"
                          name="region"
                          autoComplete="address-level1"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-green focus:ring-primary-green sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700">
                        Postal code
                      </label>

                      <div className="mt-1">
                        <input
                          type="text"
                          name="postalCode"
                          autoComplete="postal-code"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-green focus:ring-primary-green sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label htmlFor="promotionCode" className="block text-sm font-medium text-gray-700">
                        Promotion code
                      </label>

                      <div className="mt-1 flex">
                        <input
                          type="text"
                          name="promotionCode"
                          autoComplete="street-address"
                          maxLength="20"
                          className="block w-2/3 rounded-md border-gray-300 shadow-sm focus:border-primary-green focus:ring-primary-green sm:text-sm mr-2"
                          onChange={(e) => setPromotionCode(e.target.value)}
                        />

                        <button type="button" className="w-1/3 bg-blue-800 text-white rounded-md hover:bg-gray-800 transition duration-300" onClick={handlePromotionCodeSubmit}>
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-primary-green text-sm italic mt-4">ONLY CLICK “PAY NOW” ONCE, TO AVOID ANY DUPLICATE CHARGES.</div>

                <div className="mt-4 flex border-t border-gray-200 pt-6">
                  <BlackBtn type="submit" disabled={key === "21" && !promotionCode}>
                    Pay now
                  </BlackBtn>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
    </>
  );
}

