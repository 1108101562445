import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { Auth } from "aws-amplify";
import Router from "./routes";
import { restGet } from "./store/api";
import { updateCurrentUser, getUserDetails } from "./store/actions";
import GreenBtn from "./components/buttons/greenBtn";

const App = () => {
  const dispatch = useDispatch();

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [warningVisible, setWarningVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [countdown, setCountdown] = useState();
  const inactivityTimeoutRef = useRef(null);
  const warningTimeoutRef = useRef(null);
  const countdownIntervalRef = useRef(null);

  const signOut = async () => {
    try {
        await Auth.signOut();
        localStorage.setItem("logout", Date.now()); // Broadcast the logout event to other tabs
        setIsLoggedIn(false);
        window.location.reload();
    } catch (error) {
        console.error(error);
    }
};

  useEffect(() => {
    async function fetchData() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setIsLoggedIn(true);
        dispatch(updateCurrentUser(user));
      } catch (error) {
        dispatch(updateCurrentUser(null));
      }
      setIsAuthenticating(false);
    }
    fetchData();

    restGet(
      "/users/show",
      (data) => dispatch(getUserDetails(data)),
      (err) => console.error(err)
    );
  }, []);

  useEffect(() => {
    const channel = new BroadcastChannel("user-activity");

    if (isLoggedIn) {
      const signOut = async () => {
        try {
          await Auth.signOut();
          localStorage.setItem("logout", Date.now()); // Broadcast the logout event to other tabs
          setIsLoggedIn(false);
          window.location.reload();
        } catch (error) {
          console.error(error);
        }
      };

      const startWarningCountdown = () => {
        setWarningVisible(true);

        clearInterval(countdownIntervalRef.current);

        countdownIntervalRef.current = setInterval(() => {
          setCountdown((prev) => {
            if (prev <= 1) {
              clearInterval(countdownIntervalRef.current);
              setWarningVisible(false);
              signOut();
            }
            return prev - 1;
          });
        }, 1000);
      };

      const resetInactivityTimeout = () => {
        clearTimeout(inactivityTimeoutRef.current);
        clearTimeout(warningTimeoutRef.current);

        if (!warningVisible) {
          setCountdown(120);

          warningTimeoutRef.current = setTimeout(() => {
            startWarningCountdown();
          }, 30 * 60 * 1000);
        }

        inactivityTimeoutRef.current = setTimeout(() => {
          setModalVisible(true);
          signOut();
          channel.postMessage("logout");
        }, 32 * 60 * 1000);
      };

      resetInactivityTimeout();

      channel.onmessage = (event) => {
        if (event.data === "activity") {
          resetInactivityTimeout();
        } else if (event.data === "logout") {
          signOut();
        }
      };

      window.addEventListener("storage", (event) => {
        if (event.key === "lastActivity") {
          resetInactivityTimeout();
        } else if (event.key === "logout") {
          signOut();
        }
      });

      return () => {
        clearTimeout(inactivityTimeoutRef.current);
        clearTimeout(warningTimeoutRef.current);
        clearInterval(countdownIntervalRef.current);
        channel.close();
      };
    }
  }, [isLoggedIn]);

  const handleStayLoggedIn = () => {
    setWarningVisible(false);
    if (typeof inactivityTimeoutRef.current === "function") {
      inactivityTimeoutRef.current();
    }
  };

  return (
    <>
      {!isAuthenticating && <Router />}

      {warningVisible && (
        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="bg-white rounded-lg shadow-xl p-6">
              <p className="text-base font-medium text-gray-900">
                Are you still there? Your session is about to expire due to inactivity in two minutes.
              </p>
              <p className="text-base font-medium text-gray-900">
                Remaining time: {countdown} seconds
              </p>
              <div className="mt-4 flex justify-end space-x-4">
                <button
                  onClick={handleStayLoggedIn}
                  className="bg-primary-green text-white py-2 px-2 rounded"
                >
                  Stay Logged In
                </button>
                <button
                  onClick={(e) => {
                    setModalVisible(false);
                    signOut();
                  }}
                  className="bg-red-600 text-white py-2 px-2 rounded"
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {modalVisible && (
        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="bg-white rounded-lg shadow-xl p-6">
              <p className="text-lg font-medium text-gray-900">
                Your session has been logged out due to inactivity.
              </p>
              <div className="mt-4 flex justify-end">
                <GreenBtn type="button">Login</GreenBtn>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default App;
